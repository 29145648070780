import { createSlice } from "@reduxjs/toolkit";

const prisyncSlice = createSlice({
  name: "prisync",
  initialState: {
    products: [],
    loading: false,
    error: null,
    accountInfo: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setPrisyncProducts: (state, action) => {
      try {
        const products = action.payload.results || [];
        state.products = products.map((product) => {
          const transformedProduct = {
            id: product.id,
            name: product.name || "",
            brand:
              typeof product.brand === "object"
                ? product.brand.name
                : product.brand || "",
            category:
              typeof product.category === "object"
                ? product.category.name
                : product.category || "",
            product_code: product.product_code || "",
            barcode: product.barcode || "",
            cost: product.cost || "",
            competitor_urls: [],
          };

          if (Array.isArray(product.competitor_urls)) {
            transformedProduct.competitor_urls = product.competitor_urls.map(
              (url) => {
                if (typeof url === "object" && url !== null) {
                  return {
                    id: url.id || Date.now().toString(),
                    url: url.url || "",
                    product_id: product.id,
                  };
                }
                return {
                  id: Date.now().toString(),
                  url: String(url),
                  product_id: product.id,
                };
              }
            );
          }

          return transformedProduct;
        });
      } catch (error) {
        state.products = [];
      }

      state.loading = false;
      state.error = null;
    },
    addPrisyncProduct: (state, action) => {
      if (action.payload) {
        // Transform the product data to ensure proper structure
        const transformedProduct = {
          ...action.payload,
          brand:
            typeof action.payload.brand === "object"
              ? action.payload.brand.name
              : action.payload.brand,
          category:
            typeof action.payload.category === "object"
              ? action.payload.category.name
              : action.payload.category,
          competitor_urls: Array.isArray(action.payload.competitor_urls)
            ? action.payload.competitor_urls.map((url) => {
                if (typeof url === "object") {
                  return {
                    id: url.id || Date.now().toString(),
                    url: url.url || "",
                    product_id: action.payload.id,
                  };
                }
                return {
                  id: Date.now().toString(),
                  url: url,
                  product_id: action.payload.id,
                };
              })
            : [],
        };

        state.products = [transformedProduct, ...state.products];
      }
      state.loading = false;
    },
    updatePrisyncProduct: (state, action) => {
      if (action.payload) {
        state.products = state.products.map((product) =>
          product.id === action.payload.id ? action.payload : product
        );
      }
      state.loading = false;
    },
    deletePrisyncProduct: (state, action) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
      state.loading = false;
    },
    setPrisyncAccountInfo: (state, action) => {
      state.accountInfo = action.payload;
      state.loading = false;
    },
    setPrisyncBrands: (state, action) => {
      state.brands = action.payload;
      state.loading = false;
      state.error = null;
    },
    setPrisyncCategories: (state, action) => {
      state.categories = action.payload;
      state.loading = false;
      state.error = null;
    },
    addCompetitorUrl: (state, action) => {
      const { productId, url } = action.payload;
      const product = state.products.find((p) => p.id === productId);
      if (product) {
        if (!product.competitor_urls) {
          product.competitor_urls = [];
        }
        product.competitor_urls.push({
          id: url.id || Date.now().toString(),
          url: url.url || url,
          product_id: productId,
        });
      }
      state.loading = false;
    },
    deleteCompetitorUrl: (state, action) => {
      const { productId, urlId } = action.payload;
      const product = state.products.find((p) => p.id === productId);
      if (product && product.competitor_urls) {
        product.competitor_urls = product.competitor_urls.filter(
          (url) => url.id !== urlId
        );
      }
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  setError,
  setPrisyncProducts,
  setPrisyncBrands,
  setPrisyncCategories,
  setPrisyncAccountInfo,
  addPrisyncProduct,
  updatePrisyncProduct,
  deletePrisyncProduct,
  addCompetitorUrl,
  deleteCompetitorUrl,
} = prisyncSlice.actions;

export default prisyncSlice.reducer;
