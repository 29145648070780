import { combineReducers } from "redux";
import authReducer from "./authSlice";
import cartReducer from "./cartSlice";
import currencyReducer from "./currencySlice";
import phoneCaseReducer from "./phoneCaseSlice";
import subsReducer from "./subsSlice";
import layoutReducer from "./layoutSlice";
import productsReducer from "./productSlice";
import CategoryReducer from "./categorySlice";
import subCategoryReducer from "./subCategorySlice";
import LocationSlice from "./locationSlice";
import prisyncReducer from "./prisyncSlice";

const rootReducer = combineReducers({
  location: LocationSlice,
  auth: authReducer,
  cart: cartReducer,
  currency: currencyReducer,
  phoneCase: phoneCaseReducer,
  subs: subsReducer,
  subCategory: subCategoryReducer,
  layout: layoutReducer,
  products: productsReducer,
  categories: CategoryReducer,
  prisync: prisyncReducer,
});

export default rootReducer;
